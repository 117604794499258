<template>
  <div class="content" :class="isNoData1 && isNoData2 ? 'content1' : ''">
    <div class="titleWrap">
      <!--            <van-icon name="arrow-left" style="line-height: 42px"/>-->
      <div class="titleText">
        <p>{{ startAddress }}</p>
        <img
          src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/15/yuelvhuiwYaqChQanm1609141435.png"
          alt=""
        >
        <p>{{ endAddress }}</p>
      </div>
    </div>
    <div class="FlightListWrap">
      <div class="TakeTripList">
        <!--                title-->
        <div class="TakeTripListTitle">去程</div>
        <!--                日历-->
        <div class="CalendarWrap">
          <div class="dateList">
            <div v-for="(item,index) in dateTenListQ" :key="index" class="dateTenList">
              <div style="border-right: 1px solid #e8e8e8;padding: 0 10px;" @click="selDateQ(item,index)">
                <div class="dateTenStyle" :class="selDateIndexQ === index ? 'dateTenStyle1' : ''">
                  <p>{{ item.time }}</p>
                  <p>{{ item.week }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="CalendarIconWrap" @click="openCalendar(0)">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/21/yuelvhui5RGLF9uWEB1609075991.png"
              alt=""
            >
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/21/yuelvhui47hgGUtbb01609076020.png"
              alt=""
            >
          </div>
        </div>
        <!--                列表-->
        <div class="FlightListWrap">
          <div v-if="isNoData1" class="noData">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/22/yuelvhuicVzblNVGaq1609079862.png"
              alt=""
            >
            <p>很遗憾，当天没有航班，请选择其他日期</p>
          </div>
          <div v-else class="dataListWrap">
            <div
              v-for="(item,index) in dataListQ"
              :key="index"
              class="dataContent"
              :class="selDataIndexQ === index ? 'dataContent1' : ''"
              @click="SelDetailQ(item,index)"
            >
              <div class="dataList">
                <div class="FlightContent">
                  <img :src="item.flightCompanyLogo" alt="">
                  <div class="FlightTimeWrap">
                    <div class="FlightTimeStart">
                      <p>{{ item.depTime }}</p>
                      <p>{{ item.depAirport }}</p>
                    </div>
                    <img
                      src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/21/yuelvhuiHEeDUSY4uW1609075234.png"
                      alt=""
                    >
                    <div class="FlightTimeConclusion">
                      <p>{{ item.arrTime }}</p>
                      <p>{{ item.arrAirport }}{{ item.arrivingterminal }}</p>
                    </div>
                  </div>
                  <div class="FlightPriceWrap">
                    <p class="price">¥<span>{{ item.price }}</span></p>
                    <div class="discount">
                      <p v-if="item.discount === '10'">{{ item.shippingSpace }}</p>
                      <p v-else>{{ item.shippingSpace }}{{ item.discount }}折</p>
                    </div>
                  </div>
                </div>
                <div class="FlightInfo">
                  <p>{{ item.flightCompanyName }}{{ item.flightCompanyCode }}</p>
                </div>
              </div>
              <div class="van-hairline--bottom" />
            </div>
          </div>
        </div>
      </div>
      <div class="ReturnTripList">
        <!--                title-->
        <div class="TakeTripListTitle">返程</div>
        <!--                日历-->
        <div class="CalendarWrap">
          <div class="dateList">
            <div v-for="(item,index) in dateTenListF" :key="index" class="dateTenList">
              <div style="border-right: 1px solid #e8e8e8;padding: 0 10px;" @click="selDateF(item,index)">
                <div class="dateTenStyle" :class="selDateIndexF === index ? 'dateTenStyle1' : ''">
                  <p>{{ item.time }}</p>
                  <p>{{ item.week }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="CalendarIconWrap" @click="openCalendar(1)">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/21/yuelvhui5RGLF9uWEB1609075991.png"
              alt=""
            >
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/21/yuelvhui47hgGUtbb01609076020.png"
              alt=""
            >
          </div>
        </div>
        <!--                列表-->
        <div class="FlightListWrap">
          <div v-if="isNoData2" class="noData">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/22/yuelvhuicVzblNVGaq1609079862.png"
              alt=""
            >
            <p>很遗憾，当天没有航班，请选择其他日期</p>
          </div>
          <div v-else class="dataListWrap">
            <div
              v-for="(item,index) in dataListF"
              :key="index"
              class="dataContent"
              :class="selDataIndexF === index ? 'dataContent1' : ''"
              @click="SelDetailF(item,index)"
            >
              <div class="dataList">
                <div class="FlightContent">
                  <img :src="item.flightCompanyLogo" alt="">
                  <div class="FlightTimeWrap">
                    <div class="FlightTimeStart">
                      <p>{{ item.depTime }}</p>
                      <p>{{ item.depAirport }}</p>
                    </div>
                    <img
                      src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/21/yuelvhuiHEeDUSY4uW1609075234.png"
                      alt=""
                    >
                    <div class="FlightTimeConclusion">
                      <p>{{ item.arrTime }}</p>
                      <p>{{ item.arrAirport }}{{ item.arrivingterminal }}</p>
                    </div>
                  </div>
                  <div class="FlightPriceWrap">
                    <p class="price">¥<span>{{ item.price }}</span></p>
                    <div class="discount">
                      <p v-if="item.discount === '10'">{{ item.shippingSpace }}</p>
                      <p v-else>{{ item.shippingSpace }}{{ item.discount }}折</p>
                    </div>
                  </div>
                </div>
                <div class="FlightInfo">
                  <p>{{ item.flightCompanyName }}{{ item.flightCompanyCode }}</p>
                </div>
              </div>
              <div class="van-hairline--bottom" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--        没有更多了-->
    <div v-if="!isNoData1&&!isNoData2" class="noMore">
      没有更多了～
    </div>
    <!--     筛选   -->
    <div class="ScreeningWrap">
      <!--            <div class="Screening" @click="screenClick(2)">-->
      <!--                <img src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-01-05/15/yuelvhuivNO6KNcK3J1609832555.png" alt="">-->
      <!--                <p>筛选</p>-->
      <!--            </div>-->
      <div class="timeScreening" @click="screenClick(0)">
        <p v-if="sortType===3" :class="sortType===3 ? 'colorOne' : ''">时间晚-早</p>
        <p v-if="sortType===1" :class="sortType===1 ? 'colorOne' : ''">时间早-晚</p>
        <p v-if="sortType===4 || sortType===2">时间</p>
        <img v-if="sortType===1" :src="imgDecline" alt="">
        <img v-if="sortType===3" :src="imgElevation" alt="">
      </div>
      <div class="priceScreening" @click="screenClick(1)">
        <p v-if="sortType===4" :class="sortType===4 ? 'colorOne' : ''">价格高-低</p>
        <p v-if="sortType===2" :class="sortType===2 ? 'colorOne' : ''">价格低-高</p>
        <p v-if="sortType===1 || sortType=== 3">价格</p>
        <img v-if="sortType===2" :src="imgDecline" alt="">
        <img v-if="sortType===4" :src="imgElevation" alt="">
      </div>
    </div>
    <!--        底部下一步-->
    <div class="botBtnWrap">
      <div class="van-hairline--top" />
      <div class="BtnWrapList">
        <div class="BtnWrapListLeft">
          <p>¥<span>{{ totalPrice }}</span></p>
          <p>往返总价</p>
        </div>
        <div class="BtnWrapListRight" @click="goDetail">
          下一步
        </div>
      </div>
    </div>
    <!--日历-->
    <van-calendar v-model="isShowCalendar" :title="CalendarTitle" @confirm="onConfirm" />
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Calendar,
  Icon,
  Toast
} from 'vant'

Vue.use(Icon)
  .use(Calendar)

import {
  getTickedList,
  getDateTenList
} from '@/services/comeOn.js'

export default {
  name: 'RoundTripTickedList',
  data() {
    return {
      isNoData1: true, // 去程无数据
      isNoData2: true, // 返程无数据
      isType: '', // 是否单程
      startAddress: '', // 出发地
      endAddress: '', // 目的地
      startCode: '', // 出发地3字码
      endCode: '', // 目的地3字码
      isChildren: '', // 是否儿童票 0 1
      sortType: 4, // 默认1   1时间2价格3时间倒叙4价格倒叙 默认查询价格倒叙
      startTime: '', // 出发时间
      endTime: '', // 返程时间
      dateTenListQ: [], // 当前时间10天的日期
      dateTenListF: [], // 当前时间10天的日期
      selDateIndexQ: 0, // 选中去程日期
      selDateIndexF: 0, // 选择的返程日期
      isShowCalendar: false, // 日历组件
      CalendarTitle: '选择出发时间', // 日历组件title
      type: 2, // 选择的状态
      dataListQ: [], // 去程列表
      dataListF: [], // 返程列表
      getTicketListDataQ: {}, // 请求航班列表参数
      getTicketListDataF: {},
      totalPrice: 0,
      hideShare: false, // 共享航班
      flyTimeInterval1: '', // 时间段
      airlineCompanyCode1: '',
      selDataIndexQ: '', // 选择的出发行程
      selDataIndexF: '',
      priceQ: 0, // 去程金额
      priceF: 0, // 返程金额
      // isConditions:4,//筛选条件
      imgDecline: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-01-05/15/yuelvhui8DaUr9DOnW1609832621.png',
      imgElevation: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-01-05/15/yuelvhuillNG4Nzyv81609832604.png'
    }
  },
  created() {

  },
  mounted() {
    this.isType = this.$route.query ? this.$route.query.isType : this.$route.query.isType
    this.isType = this.isType === 0 ? 'OW' : 'RT'// OW 单程  RT 往返
    this.startAddress = this.$route.query ? this.$route.query.startAddress : this.$route.query.startAddress
    this.endAddress = this.$route.query ? this.$route.query.endAddress : this.$route.query.endAddress
    this.startCode = this.$route.query ? this.$route.query.startCode : this.$route.query.startCode
    this.endCode = this.$route.query ? this.$route.query.endCode : this.$route.query.endCode
    this.isChildren = this.$route.query ? this.$route.query.isChildren : this.$route.query.isChildren
    this.isChildren = this.isChildren ? 0 : 1
    // this.CurrentDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;//当前时间
    this.startTime = this.$route.query ? this.$route.query.startTime : new Date()
    this.CurrentStartDate = this.startTime// 出发日期
    this.endTime = this.$route.query ? this.$route.query.endTime : this.$route.query.endTime
    this.CurrentEndDate = this.endTime// 返回日期
    this.getDateTenLists(this.CurrentStartDate, 0)// get当前日期10天时间
    this.getDateTenLists(this.CurrentEndDate, 1)
    this.getTickedLists()// get航班列表 默认查询的航班是价格从低到高
  },
  methods: {
    // get航班列表
    getTickedLists() {
      Toast.loading('航班加载中')
      getTickedList({
        departureCity: this.startCode, // 出发城市3字码
        reachingCity: this.endCode, // 到达城市3字码
        date: this.CurrentStartDate, // 时间
        sortType: this.sortType, // 默认1   1时间2价格3时间倒叙4价格倒叙
        childNum: this.isChildren, // 是否儿童票
        tripType: this.isType, // OW 单程  RT 往返
        returnDate: this.CurrentEndDate, // 返程日期
        checkExt: {
          hideShare: this.hideShare, // true/false共享航班
          flyTimeInterval: this.flyTimeInterval1, // 时间段12：00-2：00
          aircraftPosition: '', // 舱位：经济舱
          airlineCompanyCode: this.airlineCompanyCode1// 航司号码
        }// 筛选条件
      }).then(res => {
        if (Number(res.code) === 200) {
          if (res.data.length === 0) {
            this.isNoData1 = true
            this.isNoData2 = true
          } else {
            if (res.data.depart.list.length === 0) {
              this.isNoData1 = true
            } else {
              this.isNoData1 = false
              this.dataListQ = res.data.depart.list// 去程航班列表
            }
            if (res.data.back.list.length === 0) {
              this.isNoData2 = true
            } else {
              this.isNoData2 = false
              this.dataListF = res.data.back.list// 返程航班列表
            }
            const flyTimeIntervalList = res.data.depart.checkList.flyTimeIntervalList
            const airlineCompanyList = res.data.depart.checkList.airlineCompanyList
            // 时间列表
            for (let i = 0; i < flyTimeIntervalList.length; i++) {
              flyTimeIntervalList[i].isSel = false
            }
            this.TimeDepartureList = flyTimeIntervalList
            // 航司列表
            for (let i = 0; i < airlineCompanyList.length; i++) {
              airlineCompanyList[i].isSel = false
            }
            this.AviationDivisionList = airlineCompanyList
          }
        } else {
          this.dataListQ = []// 去程航班列表
          this.dataListF = []// 返程航班列表
          this.isNoData1 = true
          this.isNoData2 = true
        }
        Toast.clear()
      })
    },
    // get当前时间10天的时间
    getDateTenLists(CurrentDate, type) {
      getDateTenList({
        date: CurrentDate// 2020-07-28
      }).then(res => {
        if (type === 0) { // 去程
          this.dateTenListQ = res
          for (let i = 0; i < res.length; i++) {
            res[i].time = res[i].date.substring(5)
            this.dateTenListF.concat(res[i])
          }
        } else if (type === 1) { // 返程
          this.dateTenListF = res
          for (let i = 0; i < res.length; i++) {
            res[i].time = res[i].date.substring(5)
            this.dateTenListQ.concat(res[i])
          }
        }
      })
    },
    // 选择去程时间
    selDateQ(item, index) {
      this.selDateIndexQ = index
      this.CurrentStartDate = item.date
      this.priceQ = 0
      this.priceF = 0
      this.totalPrice = 0
      this.selDataIndexQ = ''
      this.selDataIndexF = ''
      this.getTickedLists()// 搜索航班
    },
    // 选择返程时间
    selDateF(item, index) {
      this.selDateIndexF = index
      this.CurrentEndDate = item.date
      this.priceQ = 0
      this.priceF = 0
      this.totalPrice = 0
      this.selDataIndexQ = ''
      this.selDataIndexF = ''
      this.getTickedLists()// 搜索航班
    },
    // 打开日历并选择
    openCalendar(type) {
      this.isShowCalendar = true
      this.type = type
      this.priceQ = 0
      this.priceF = 0
      this.totalPrice = 0
      this.selDataIndexQ = ''
      this.selDataIndexF = ''
      if (type === 0) { // 去程
        this.CalendarTitle = '选择出发时间'
        this.selDateIndexQ = 0
      } else if (type === 1) { // 返程
        this.CalendarTitle = '选择返回时间'
        this.selDateIndexF = 0
      }
    },
    // 转化日历组件返回的日期格式
    formatDate(date) {
      let month, day
      if (date.getMonth() + 1 < 10) {
        month = '0' + (date.getMonth() + 1)
      } else {
        month = date.getMonth() + 1
      }
      if (date.getDate() < 10) {
        day = '0' + date.getDate()
      } else {
        day = date.getDate()
      }
      return `${date.getFullYear()}-${month}-${day}`
      // return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    // 日历日期选择
    onConfirm(date) {
      if (this.type === 0) {
        this.CurrentStartDate = this.formatDate(date)// 选择的时间
        this.getDateTenLists(this.CurrentStartDate, 0)
        this.getTickedLists()
      } else if (this.type === 1) {
        this.CurrentEndDate = this.formatDate(date)
        this.getDateTenLists(this.CurrentEndDate, 1)
        this.getTickedLists()
      }
      this.isShowCalendar = false
    },
    // 点击搜索
    clickSearch(item) {
      item.searchSwitch = !item.searchSwitch
      if (item.searchSwitch === true) {
        this.btnIndex = item.code
        this.searchIndex = item.code
      } else {
        this.btnIndex = item.code
      }
    },
    // 选择出发行程
    SelDetailQ(item, index) {
      this.selDataIndexQ = index
      this.priceQ = item.price
      this.flightInfoId = item.flightinfoid
      this.totalPrice = this.priceQ + this.priceF
    },
    // 选择返回行程
    SelDetailF(item, index) {
      this.priceF = item.price
      this.rtFlightInfoId = item.flightinfoid
      this.totalPrice = this.priceQ + this.priceF
      this.selDataIndexF = index
    },
    // 下一步
    goDetail() {
      if (this.priceQ === 0 || this.priceF === 0) {
        Toast('请选择航班')
        return
      }
      this.$router.push({
        path: '/roundTripTickedDetail',
        query: {
          startAddress: this.startAddress,
          endAddress: this.endAddress,
          flightInfoId: this.flightInfoId, // foid
          childNum: this.isChildren, // 儿童票 1/0
          tripType: this.isType, // 行程类型 OW单程RT往返
          rtFlightInfoId: this.rtFlightInfoId// 返程foid
        }
      })
    },
    // 筛选
    screenClick(type) {
      if (type === 0) { // 时间筛选
        if (this.sortType !== 3 && this.sortType !== 1) {
          this.sortType = 3
        } else {
          if (this.sortType === 1) {
            this.sortType = 3
          } else if (this.sortType === 3) {
            this.sortType = 1
          }
        }
      } else if (type === 1) { // 价格筛选
        if (this.sortType !== 2 && this.sortType !== 4) {
          this.sortType = 4
        } else {
          if (this.sortType === 2) {
            this.sortType = 4
          } else if (this.sortType === 4) {
            this.sortType = 2
          }
        }
      } else if (type === 2) { // 筛选

      }
      this.getTickedLists()
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
}

.content1 {
  background-color: #fff;
}

.content {
  position: absolute;
  background-color: #f8f8f8;

  .titleWrap {
    width: 375px;
    height: 42px;
    background-color: #fff;
    text-align: center;
    line-height: 42px;
    display: flex;
    justify-content: center;

    .titleText {
      display: flex;
      line-height: 42px;

      p {
        font-size: 17px;
        color: #333333;
        font-weight: 600;
      }

      img {
        width: 16px;
        height: 13px;
        margin: 13px 8px;
      }
    }
  }

  /*航班列表*/

  .FlightListWrap {
    display: flex;
    height: 86%;
    flex-shrink: 0;
    overflow-x: scroll;
    white-space: nowrap;

    .TakeTripList, .ReturnTripList {
      width: 273px;
      position: relative;
      flex-shrink: 0;

      .TakeTripListTitle {
        width: 100%;
        height: 44px;
        /*background-color: #F7263C;*/
        background: -webkit-linear-gradient(left, #FF6F29, #F7263C, #FF5C57);
        font-size: 15px;
        color: #ffffff;
        line-height: 44px;
        padding-left: 16px;
        text-align: left;

      }
    }

    .TakeTripList {
      border-radius: 0 7px 0 0;

      .TakeTripListTitle {
        border-radius: 0 7px 0 0;
        /*position: fixed;*/
      }
    }

    .ReturnTripList {
      border-radius: 7px 0 0 0;
      margin-left: 6px;

      .TakeTripListTitle {
        border-radius: 7px 0 0 0;
      }
    }

    /*    日历*/

    .CalendarWrap {
      /*position: fixed;*/
      z-index: 1;
      top: 42px;
      left: 0;
      width: 100%;
      height: 67px;
      background-color: #fff;
      display: flex;
      /*10天数据*/

      .dateList {
        display: flex;
        overflow: scroll;
        white-space: nowrap;
        width: 80%;

        .dateTenList {
          width: 69%;
          padding: 10px 0;
          font-size: 12px;
          color: #333333;

          .dateTenStyle {
            line-height: 20px;
            padding: 4px 10px;
            border-radius: 3px;
          }

          .dateTenStyle1 {
            /*background-color: #E33F44;*/
            border-radius: 0px;
            color: #E33F44;
            border-bottom: 2px solid #E33F44;
          }
        }
      }

      .CalendarIconWrap {
        width: 20%;
        text-align: center;
        line-height: 67px;
        border-left: 1px solid #f8f8f8;
        position: relative;
        /*box-shadow:1px 0 10px 0px #aaa;*/
        //box-shadow: -5px 0 3px -3px #aaa;
        img:nth-child(1) {
          position: absolute;
          width: 18px;
          height: 18px;
          left: 50%;
          margin-left: -9px;
          top: 50%;
          margin-top: -9px;
        }

        img:nth-child(2) {
          position: absolute;
          width: 8px;
          height: 5px;
          left: 50%;
          margin-left: -4px;
          top: 80%;
          margin-top: -8px;
        }
      }
    }

    /*    航班列表*/

    .FlightListWrap {
      position: relative;
      background-color: #ffffff;

      .noData {
        position: absolute;
        text-align: center;
        top: 102px;
        left: 50%;
        margin-left: -110px;

        img {
          width: 135px;
          height: 121px;
        }

        p {
          font-size: 12px;
          color: #999999;
        }
      }

      .dataContent {
        background-color: #fff;
      }

      .dataContent1 {
        background-color: #FCEEEE;
        /*border: 1px solid #E33F44;*/
      }

      .dataListWrap {
        /*margin-top: 119px;*/
        width: 100%;
        height: 100vh;

        .dataList {
          padding: 23px 17px 16px 17px;

          .FlightContent {
            display: flex;

            img {
              width: 20px;
              height: 23px;
              margin-right: 7px;
            }

            .FlightTimeWrap {
              display: flex;
              width: 60%;

              .FlightTimeStart, .FlightTimeConclusion {
                color: #333333;

                p:nth-child(1) {
                  font-size: 18px;
                  font-weight: 500;
                }

                p:nth-child(2) {
                  font-size: 11px;
                  margin-top: 7px;
                }
              }

              img {
                width: 33px;
                height: 5px;
                margin: 6px;
              }

            }

            .FlightPriceWrap {
              margin-left: auto;

              .price {
                font-size: 11px;
                color: #E33F44;

                span {
                  display: inline-block;
                  font-size: 18px;
                  margin-left: 2px;
                }
              }

              .discount {
                font-size: 12px;
                color: #999999;
                margin-top: 7px;
              }
            }
          }

          .FlightInfo {
            display: flex;
            margin-left: 30px;
            margin-top: 13px;

            p {
              font-size: 12px;
              color: #999999;
            }
          }
        }
      }
    }
  }

  .noMore {
    margin-top: 17px;
    font-size: 12px;
    color: #999999;
    text-align: center;
  }

  /*筛选*/

  .ScreeningWrap {
    position: fixed;
    bottom: 93px;
    left: 0;
    /*width:250px;*/
    height: 36px;
    text-align: center;
    /*line-height: 36px;*/
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 0 18px 18px 0;
    display: flex;
    padding: 10px 0;

    .Screening, .timeScreening, .priceScreening {
      flex: auto;
      display: flex;
      font-size: 12px;
      color: #333333;
      padding: 0 10px;
      text-align: center;

      img {
        vertical-align: center;
      }

      p {
        padding: 0 5px;
      }
    }

    .Screening, .timeScreening {
      border-right: 1px solid #e8e8e8;
    }

    .timeScreening, .priceScreening {
      img {
        width: 7px;
        height: 4px;
        margin-top: 5px;
      }
    }

    .Screening {
      img {
        width: 14px;
        height: 14px;
      }
    }

    .colorOne {
      color: #E33F44;
    }
  }

  /*    底部选择*/

  .botBtnWrap {
    position: fixed;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 57px;

    .BtnWrapList {
      width: 100%;
      height: 100%;
      background-color: #fff;
      display: flex;
      padding: 0 17px;

      .BtnWrapListLeft {
        margin-top: 13px;

        p:nth-child(1) {
          font-size: 12px;
          color: #E33F44;

          span {
            font-size: 20px;
            margin-left: 2px;
          }
        }

        p:nth-child(2) {
          font-size: 12px;
          color: #666666;
        }
      }

      .BtnWrapListRight {
        margin-left: auto;
        width: 110px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 15px;
        color: #ffffff;
        background-color: #E33F44;
        border-radius: 4px;
        margin-top: 11px;
      }
    }
  }
}
</style>
